/**
 *  Personal Site: My humble personal homepage, made with a tiny bit but not much care.
 *  <https://github.com/MattIPv4/Personal-Site/>
 *  Copyright (C) 2023 Matt Cowley (MattIPv4) (me@mattcowley.co.uk)
 *
 *  This program is free software: you can redistribute it and/or modify
 *   it under the terms of the GNU General Public License as published
 *   by the Free Software Foundation, either version 3 of the License, or
 *   (at your option) any later version.
 *  This program is distributed in the hope that it will be useful,
 *   but WITHOUT ANY WARRANTY; without even the implied warranty of
 *   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *   GNU General Public License for more details.
 *  You should have received a copy of the GNU General Public License
 *   along with this program. If not, please see
 *   <https://github.com/MattIPv4/Personal-Site/blob/master/LICENSE.md> or <http://www.gnu.org/licenses/>.
 */

@use "sass:color";

$dark: #14151a;
$light: color.adjust($dark, $lightness: 50%);
$white: #fff;
$link: #1a79ff;

html,
body {
  background: $dark;
  color: $white;
}

@mixin text {
  font-size: 1.2rem;
  font-weight: 400;
}

@mixin command {
  color: $light;
  font-size: 1.5rem;
  margin: 1.5rem 0 .5rem;
  word-break: break-all;
}

.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    @include text;
  }

  h1 {
    margin: 0 0 1rem;

    &::before {
      @include command;

      content: "# Hello";
      display: block;
    }
  }

  h3.featured,
  h3.email,
  h4,
  h5,
  footer > p:first-child {
    @include command;

    &::before {
      content: "# ";
    }
  }

  h3.email {
    a {
      @include text;

      display: block;
      margin: .5rem 0 0;
    }
  }

  a {
    &,
    &:visited {
      color: $link;
    }

    &:hover,
    &:focus {
      color: color.adjust($link, $lightness: 20%);
    }
  }

  .projects {
    border-left: 2px solid $white;
    padding: 0 0 0 1.5rem;
    margin: 1rem 0 0 1.1rem;

    .project {
      position: relative;

      &::before {
        display: block;
        content: "";
        width: .75rem;
        height: 2px;
        background: $white;
        position: absolute;
        left: -1.5rem;
        top: 1.25rem;
      }

      &:not([open]) {
        .project-info {
          &::after {
            display: block;
            color: $light;
            content: "[...]";
            position: absolute;
            bottom: -1.5em;
          }
        }
      }

      .project-info {
        border: 2px solid transparent;
        margin: 0 -.5em 1.5em;
        padding: 0 .5em;
        position: relative;

        &:hover,
        &:focus {
          border: 2px dashed $white;
          background: none;
          border-radius: 0;
        }

        .project-meta {
          .project-role,
          .project-title {
            @include text;
          }

          .project-details {
            border-left-color: $light;
            margin: .5em 0;
          }

          .project-tags {
            margin: .5em -.5em;

            .project-tag {
              border: 2px solid $light;
              border-radius: 0;
              background: none;
              color: $light;
              padding: .2em .4em;
            }
          }
        }
      }

      .project-desc {
        margin: -.5em 0 0;
      }
    }
  }

  footer {
    margin: 4rem 0 0;

    p {
      &:first-child {
        font-size: 1.2rem;
      }
    }
  }
}
